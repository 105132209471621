import EnvironmentTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-oiliness/environment/EnvironmentTemplate';

export default {
  title: 'Steps/SkinOiliness/Environment/EnvironmentTemplate',
  component: EnvironmentTemplate
};

const createStory = props => () => ({
  components: { EnvironmentTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><environment-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  onlyGreasyInSummer: 'yes'
});
